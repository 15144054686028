import React from 'react';
import classNames from 'classnames';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

export default function CTA(props) {
   const textColor = classNames('block', props.textColor);
   const bgColor = classNames(
      'inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white',
      props.bgColor,
      props.bgHover
   );
   return (
      <div className='bg-gray-50'>
         <div className='max-w-5xl mx-auto py-12 text-center md:text-left px-8 sm:px-6 lg:py-24 lg:px-8 flex flex-col md:flex-row justify-center lg:items-center lg:justify-between'>
            <h2 className='text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl pr-4'>
               <span className='block'>Ready to get started?</span>
               <span className={textColor}>Learn how we can help you.</span>
            </h2>
            <div className='mt-8 flex mx-auto md:mx-0 lg:mt-0 lg:flex-shrink-0'>
               <div className='inline-flex rounded-md shadow'>
                  <AnchorLink to='/snow#contact' className={bgColor}>
                     Get in touch
                  </AnchorLink>
               </div>
            </div>
         </div>
      </div>
   );
}
