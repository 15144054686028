import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

export default function SnowLinks() {
   const data = useStaticQuery(graphql`
      query {
         allSnowservice: allSnowservice {
            edges {
               node {
                  name
                  link
                  icon
                  alt
               }
            }
         }
      }
   `);
   const snows = data.allSnowservice.edges;
   return (
      <div className='bg-snow w-full text-white'>
         <div className='flex justify-between items-start max-w-7xl mx-auto px-3 md:px-4 lg:px-8'>
            {snows.map((snow) => (
               <div key={snow.id} className='my-4 px-2 flex-1'>
                  <Link
                     to={snow.node.link}
                     style={{ fontFamily: 'Muli' }}
                     className='flex flex-col items-center justify-between text-center max-h-full text-xs md:text-sm hover:underline text-white'
                  >
                     {' '}
                     <div className='w-4 md:w-6 mb-2 flex items-start justify-start'>
                        <img src={snow.node.icon} alt={snow.node.alt} className='object-fill h-6 w-6' />
                     </div>
                     {snow.node.name}
                  </Link>
               </div>
            ))}
         </div>
      </div>
   );
}
