import React from 'react';

import Menu from './SnowMenu';
import Footer from './footer';

import '../styles/global.css';
import '@fontsource/muli';

export default function SnowLayout({ children }) {
   return (
      <>
         <Menu />
         {children}
         <Footer bgColor='bg-snow-dark' textColor='text-white' footerColor='text-snow' />
      </>
   );
}
